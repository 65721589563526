import logo from '../assets/logo.png'

const Logo = () => {
  return (
    <div className="pl-[45px] pt-[25px]">
        <img src={logo} alt="logo" className='w[63px] h-[43px]'/>
    </div>
  )
}

export default Logo